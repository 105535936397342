import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Tooltip } from '@material-ui/core';
import { RouteChildrenProps } from 'react-router-dom';
import { AxiosError } from 'axios';
import { produce } from 'immer';
import SortableTree, {
  removeNodeAtPath,
  addNodeUnderParent,
  changeNodeAtPath,
  TreeItem,
  getFlatDataFromTree,
  toggleExpandedForAll,
} from 'react-sortable-tree';
import { Add, Delete, Settings } from '@material-ui/icons';

import 'react-sortable-tree/style.css';

import {
  createAssociation,
  uploadFiles,
  documentsAssociation,
  loadAssociations,
  updateAssociation,
} from '~/services/api';
import Snackbar, { SeverityType } from '~/components/Snackbar';
import InputComponent from '~/components/InputComponent';
import Inputfile from '~/components/Inputfile';
import Button from '~/components/Button';
import { validCNPJ, validEmail } from '~/util/validate';
import { removeFormat, formatCNPJ, formatZipCode, onlyNumbers } from '~/util/format';
import { Form, ConfigList } from './styles';
import { AssociationResponse } from '~/types';
import { useToast } from '~/services/hooks/toast';
import { handleApiErrorResponse } from '~/services/handleErrors';
import Modal from '~/components/Modal';
import { Title } from '~/components/Title';
import Loading from '~/components/Loading';

interface RegisterAssociationParams {
  associationId?: string;
}

interface RegisterAssociationLocationState {}

type RegisterAssociationProps = RouteChildrenProps<RegisterAssociationParams, RegisterAssociationLocationState>;

type CustomFormFieldValues = {
  code: string;
  social_identity: string;
  phone_number: string;
  company_name: string;
  trading_name: string;
  description: string;
  address?: {
    zip_code: string;
    city: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
  };
  email: string;
  configuration_value: {
    hinova: Record<string, any>;
    calculation: {
      travel_outside_the_benefit_city: {
        allowed: boolean;
        limit: number;
      };
      intermediate_prices?: {
        allowed: boolean;
      };
      output_limit?: number;
    };
  };
  // TODO - inicio - remover depois de remover da API
  charge_excess?: boolean;
  diff_source_destination?: boolean;
  // TODO - fim - remover depois de remover da API
};

const INITIAL_VALUE: TreeItem<CustomProps>[] = [
  {
    id: 'from_to_service',
    title: 'Serviço',
    header: true,
    expanded: true,
    canHaveChildren: true,
    children: [],
  },
  {
    id: 'from_to_vehicle',
    title: 'Veículo',
    header: true,
    expanded: true,
    canHaveChildren: true,
    children: [],
  },
  {
    id: 'from_to_status',
    title: 'Status',
    header: true,
    expanded: true,
    canHaveChildren: true,
    children: [],
  },
];

type CustomProps = {
  id: string;
  needsTitle?: boolean;
  canHaveChildren?: boolean;
  header?: boolean;
};

type NumberOrStringArray = (number | string)[];

export default function RegisterAssociation({ match }: RegisterAssociationProps) {
  const associationId = match?.params?.associationId;

  const [cpnjCardName, setCpnjCardName] = useState('Selecione um arquivo');
  const [socialContract, setSocialContract] = useState('Selecione um arquivo');
  const [proofAddress, setProofAddress] = useState('Selecione um arquivo');
  const [loadingAssociation, setLoadingAssociation] = useState(false);
  const [association, setAssociation] = useState<AssociationResponse>();
  const [fileCardName, setFileCardName] = useState<File>();
  const [fileSocialContract, setFileSocialContract] = useState<File>();
  const [fileProofAddress, setFileProofAddress] = useState<File>();
  const [messageSnack, setMessageSnack] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('info');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<TreeItem<CustomProps>[]>(INITIAL_VALUE);
  const [fromTo, setFromTo] = useState<Record<string, string[]>>();
  const [activeSync, setActiveSync] = useState<boolean>();

  const { handleSubmit, errors, control, reset, getValues } = useForm<CustomFormFieldValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    submitFocusError: true,
  });
  const { addToast } = useToast();

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!e.target.files) {
      return;
    }

    switch (e.target.name) {
      case 'cnpj_card':
        setCpnjCardName(e.target.files[0] ? e.target.files[0].name : 'Selecione um arquivo');
        setFileCardName(e.target.files[0]);
        break;
      case 'social_contract':
        setSocialContract(e.target.files[0] ? e.target.files[0].name : 'Selecione um arquivo');
        setFileSocialContract(e.target.files[0]);
        break;
      case 'proof_address':
        setProofAddress(e.target.files[0] ? e.target.files[0].name : 'Selecione um arquivo');
        setFileProofAddress(e.target.files[0]);
        break;
      default:
        break;
    }
  };

  const clearFile = (input: string) => {
    switch (input) {
      case 'cnpj_card':
        setCpnjCardName('Selecione um arquivo');
        setFileCardName(undefined);
        break;

      case 'social_contract':
        setSocialContract('Selecione um arquivo');
        setFileSocialContract(undefined);
        break;

      case 'proof_address':
        setProofAddress('Selecione um arquivo');
        setFileProofAddress(undefined);
        break;

      case 'all':
        setCpnjCardName('Selecione um arquivo');
        setFileCardName(undefined);
        setSocialContract('Selecione um arquivo');
        setFileSocialContract(undefined);
        setProofAddress('Selecione um arquivo');
        setFileProofAddress(undefined);
        break;

      default:
        break;
    }
  };

  const onSubmit = async (data: CustomFormFieldValues) => {
    setLoading(true);

    // Data formatter
    const newData = produce(data, draft => {
      draft.phone_number = `0${removeFormat(data.phone_number)}`;
      if (draft.address) {
        draft.address.zip_code = removeFormat(data?.address?.zip_code);
      }

      if (draft.configuration_value?.hinova) {
        draft.configuration_value.hinova = { ...data.configuration_value?.hinova, ...fromTo };
      }

      draft.diff_source_destination = data.configuration_value.calculation.travel_outside_the_benefit_city.allowed;

      draft.charge_excess = data.configuration_value.calculation.intermediate_prices?.allowed;
    });

    try {
      const response = !associationId
        ? await createAssociation(newData)
        : await updateAssociation(associationId, newData);
      const { location } = response.headers;

      if (location) {
        const urlSplit = location.split('/');
        const idAssociation = urlSplit[urlSplit.length - 1];

        if (idAssociation) {
          if (fileCardName) {
            const formDataCardName = new FormData();
            formDataCardName.append('file', fileCardName);
            const responseCardName = await uploadFiles(formDataCardName);

            const urlCardName = responseCardName.data.url;

            if (urlCardName) {
              await documentsAssociation(idAssociation, {
                type: 'IMG_CNPJ',
                value: JSON.stringify({ path: urlCardName }),
              });
            }
          }

          if (fileSocialContract) {
            const formDataSocialContract = new FormData();
            formDataSocialContract.append('file', fileSocialContract);
            const responseSocialContract = await uploadFiles(formDataSocialContract);

            const urlSocialContract = responseSocialContract.data.url;

            if (urlSocialContract) {
              await documentsAssociation(idAssociation, {
                type: 'IMG_SOCIAL_CONTRACT',
                value: JSON.stringify({ path: urlSocialContract }),
              });
            }
          }

          if (fileProofAddress) {
            const formDataProofAddress = new FormData();
            formDataProofAddress.append('file', fileProofAddress);
            const responseProofAddress = await uploadFiles(formDataProofAddress);

            const urlProofAddress = responseProofAddress.data.url;

            if (urlProofAddress) {
              await documentsAssociation(idAssociation, {
                type: 'IMG_PROOF_OF_RESIDENCE',
                value: JSON.stringify({ path: urlProofAddress }),
              });
            }
          }
        }
      }

      if (!associationId) {
        reset();
        clearFile('all');
      }

      setMessageSnack(`${!associationId ? 'Cadastro realizado' : 'Atualização realizada'} com sucesso!`);
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (err) {
      const errorAxios = err as AxiosError;
      setSeverity('warning');
      setMessageSnack(errorAxios.response?.data || err);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const getPhoneNumber = () => {
    const phone = association?.phone_number;
    if (!phone) {
      return '';
    }

    const phoneWithoutZeroLeft = phone.startsWith('0')
      ? phone.slice(1).replace(/(\d{2})(\d{4,5})(\d{4})/, '($1)$2-$3')
      : phone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1)$2-$3');
    return phoneWithoutZeroLeft;
  };

  const handleToggleModal = () => {
    setShowModal(prev => !prev);
  };

  const onChangeInput = (node: TreeItem<CustomProps>, path: NumberOrStringArray, title: string) => {
    const newTree = changeNodeAtPath({
      treeData: data,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: { ...node, title },
    });

    setData(newTree);
  };

  const removeNode = (path: NumberOrStringArray) => {
    setData(
      removeNodeAtPath({
        treeData: data,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: false,
      }),
    );
  };

  const addQuestion = (node: TreeItem<CustomProps>, path: NumberOrStringArray) => {
    const newTree = addNodeUnderParent({
      treeData: data,
      expandParent: true,
      parentKey: path[path.length - 1],
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: {
        id: Math.random().toString(),
        title: '',
        needsTitle: true,
        canHaveChildren: node.header,
      },
    });
    setData(newTree.treeData);
  };

  const convertFlatToTree = (treeData: TreeItem<CustomProps>[]) => {
    const treeDataExpanded = toggleExpandedForAll({ treeData, expanded: true });
    const flat = getFlatDataFromTree({
      treeData: treeDataExpanded,
      getNodeKey: ({ node }) => node.id,
    });

    const dataFlat = flat.reduce((acc, item) => {
      switch (item.path.length) {
        case 1:
          acc[item.node.id] = {};
          break;

        case 2:
          acc[item.path[0]][item.node.title] = [];
          break;

        case 3:
          acc[item.path[0]][item.parentNode.title] = [...acc[item.path[0]][item.parentNode.title], item.node.title];
          break;

        default:
          break;
      }
      return acc;
    }, {});

    return dataFlat;
  };

  const handleGetData = () => {
    const dataFlat = convertFlatToTree(data);

    setFromTo(dataFlat);
    handleToggleModal();
  };

  useEffect(() => {
    setActiveSync(association?.configuration_value?.hinova?.active_sync);
  }, [association]);

  useEffect(() => {
    const loadAssociation = async () => {
      if (!associationId) {
        return;
      }

      try {
        setLoadingAssociation(true);

        const { data } = await loadAssociations(associationId);
        setAssociation(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar a empresa');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      } finally {
        setLoadingAssociation(false);
      }
    };

    loadAssociation();
  }, [addToast, associationId]);

  useEffect(() => {
    const hinovaConfig = association?.configuration_value?.hinova;

    if (hinovaConfig) {
      Object.keys(hinovaConfig)
        .filter(key => ['from_to_service', 'from_to_vehicle', 'from_to_status'].includes(key))
        .forEach(item => {
          const hinovaConfigFromTo = Object.entries(hinovaConfig[item]).map(([key, value]) => {
            const ret: TreeItem<CustomProps> = {
              canHaveChildren: true,
              id: Math.random().toString(),
              needsTitle: true,
              title: key,
              expanded: true,
              children: (value as string[]).map(item => ({
                id: Math.random().toString(),
                needsTitle: true,
                title: item,
              })),
            };
            return ret;
          });

          setData(prev => {
            const newData = produce(prev, draft => {
              const indexFromToService = prev.findIndex(prevItem => prevItem.id === item);
              if (indexFromToService >= 0) {
                draft[indexFromToService].children = hinovaConfigFromTo;
              }
            });

            const dataFlat = convertFlatToTree(newData);

            setFromTo(dataFlat);
            return newData;
          });
        });
    }
  }, [association]);

  if (loadingAssociation) {
    return <Loading />;
  }

  return (
    <>
      <Title>Cadastrar Empresa</Title>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={3}>
            <Controller
              as={<InputComponent disabled={loading} label='Código da empresa' error={errors.code} />}
              name='code'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.code}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              as={
                <InputComponent
                  disabled={loading}
                  label='CNPJ'
                  error={errors.social_identity}
                  inputProps={{
                    maxLength: 18,
                  }}
                />
              }
              name='social_identity'
              onChange={([event]) => formatCNPJ(removeFormat(event.target.value))}
              rules={{
                required: 'Obrigatório',
                validate: value => validCNPJ(removeFormat(value)) || 'CNPJ inválido',
              }}
              control={control}
              defaultValue={formatCNPJ(
                (JSON.parse(association?.documents.filter(document => document.type === 'CNPJ')[0]?.value || '{}') as {
                  identifier?: string;
                }).identifier,
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              as={<InputComponent disabled={loading} label='Razão Social' error={errors.company_name} />}
              name='company_name'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.company_name}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={<InputComponent disabled={loading} label='Nome fantasia' error={errors.trading_name} />}
              name='trading_name'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.trading_name}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              as={
                <InputComponent
                  disabled={loading}
                  label='Código e descrição da natureza jurídica'
                  error={errors.description}
                />
              }
              name='description'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.description}
            />
          </Grid>
          <Grid item lg={2} md={3} sm={3} xs={4}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='CEP'
                  error={errors.address && errors.address['zip_code']}
                  inputProps={{
                    maxLength: 9,
                  }}
                />
              }
              name='address.zip_code'
              rules={{
                required: 'Obrigatório',
                pattern: {
                  value: /[0-9]{5}-[0-9]{3}/,
                  message: 'CEP Inválido',
                },
              }}
              onChange={([event]) => formatZipCode(removeFormat(event.target.value))}
              control={control}
              defaultValue={formatZipCode(association?.address.zip_code)}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='Logradouro'
                  error={errors.address && errors.address['street']}
                />
              }
              name='address.street'
              rules={{
                required: 'Obrigatório',
              }}
              control={control}
              defaultValue={association?.address.street}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='Número'
                  error={errors.address && errors.address['number']}
                />
              }
              name='address.number'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.address.number}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='Complemento'
                  error={errors.address && errors.address['complement']}
                />
              }
              name='address.complement'
              control={control}
              defaultValue={association?.address.complement}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='Bairro'
                  error={errors.address && errors.address['neighborhood']}
                />
              }
              name='address.neighborhood'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.address.neighborhood}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='Município'
                  error={errors.address && errors.address['city']}
                />
              }
              name='address.city'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.address.city}
            />
          </Grid>
          <Grid item lg={1} md={2} sm={2} xs={2}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='UF'
                  error={errors.address && errors.address['state']}
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              }
              name='address.state'
              rules={{ required: 'Obrigatório', maxLength: 2 }}
              onChange={([event]) => event.target.value.toUpperCase()}
              control={control}
              defaultValue={association?.address.state}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={3} xs={3}>
            <Controller
              as={
                <InputComponent
                  disabled={loading || associationId}
                  label='País'
                  error={errors.address && errors.address['city']}
                />
              }
              name='address.country'
              rules={{ required: 'Obrigatório' }}
              control={control}
              defaultValue={association?.address.country}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Controller
              as={<InputComponent disabled={loading} label='E-mail para contato' error={errors.email} />}
              name='email'
              control={control}
              rules={{
                required: false,
                validate: value => !value || validEmail(value) || 'E-mail inválido',
              }}
              defaultValue={association?.email}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Controller
              as={
                <InputComponent
                  disabled={loading}
                  label='Telefone'
                  error={errors.phone_number}
                  inputProps={{
                    maxLength: 14,
                  }}
                />
              }
              name='phone_number'
              control={control}
              rules={{
                required: 'Obrigatório',
                pattern: {
                  value: /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})/,
                  message: 'Telefone inválido: Ex.: (00)00000-0000',
                },
              }}
              onChange={([event]) =>
                removeFormat(event.target.value)
                  .replace(/(\d{2})(\d{4,5})(\d{4})/, '($1)$2-$3')
                  .trim()
              }
              defaultValue={getPhoneNumber()}
            />
          </Grid>

          <Grid item xs={5}>
            <FormLabel component='legend'>Permite viagem para fora da cidade do cadastro do veículo?</FormLabel>
            <Controller
              as={
                <RadioGroup name='travel_outside_the_benefit_city' row>
                  <FormControlLabel value='true' control={<Radio color='primary' />} label='Sim' />
                  <FormControlLabel value='false' control={<Radio color='primary' />} label='Não' />
                </RadioGroup>
              }
              name='configuration_value.calculation.travel_outside_the_benefit_city.allowed'
              control={control}
              rules={{
                required: 'Obrigatório',
              }}
              defaultValue={`${
                association?.diff_source_destination ||
                association?.configuration_value?.calculation?.travel_outside_the_benefit_city.allowed ||
                false
              }`}
            />
          </Grid>

          <Grid item xs={3}>
            <Controller
              as={
                <InputComponent
                  disabled={loading}
                  label='Limite de KM da permissão'
                  error={errors.configuration_value?.calculation?.travel_outside_the_benefit_city?.limit}
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              }
              name='configuration_value.calculation.travel_outside_the_benefit_city.limit'
              control={control}
              rules={{
                validate: {
                  require: value => {
                    if (
                      !value &&
                      (getValues()['configuration_value.calculation.travel_outside_the_benefit_city.allowed'] ===
                        'true' ||
                        association?.configuration_value?.calculation?.travel_outside_the_benefit_city.allowed)
                    ) {
                      return 'Obrigatório';
                    }

                    return true;
                  },
                },
              }}
              defaultValue={association?.configuration_value?.calculation?.travel_outside_the_benefit_city?.limit}
              onChange={([event]) => onlyNumbers(event.target.value)}
            />
          </Grid>

          <Grid item xs={4} />

          <Grid item xs={5}>
            <FormLabel component='legend'>Utilizará tabela de preço intermediária para excedente?</FormLabel>
            <Controller
              as={
                <RadioGroup name='intermediate_prices' row>
                  <FormControlLabel value='true' control={<Radio color='primary' />} label='Sim' />
                  <FormControlLabel value='false' control={<Radio color='primary' />} label='Não' />
                </RadioGroup>
              }
              name='configuration_value.calculation.intermediate_prices.allowed'
              control={control}
              rules={{
                required: 'Obrigatório',
              }}
              defaultValue={`${
                association?.charge_excess ||
                association?.configuration_value?.calculation?.intermediate_prices?.allowed ||
                false
              }`}
            />
          </Grid>

          <Grid item xs={7} />

          <Grid item xs={3}>
            <Controller
              as={
                <InputComponent
                  disabled={loading}
                  label='KM de saída do reboque'
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              }
              name='configuration_value.calculation.output_limit'
              control={control}
              defaultValue={association?.configuration_value?.calculation?.output_limit}
              onChange={([event]) => onlyNumbers(event.target.value)}
            />
          </Grid>

          <Grid item xs={9} />

          <Grid item xs={4}>
            <FormLabel component='legend'>Habilitar integração com a Hinova?</FormLabel>
            <Controller
              as={
                <RadioGroup name='active_sync' row>
                  <FormControlLabel
                    value='true'
                    control={<Radio color='primary' />}
                    label='Sim'
                    onClick={() => setActiveSync(true)}
                  />
                  <FormControlLabel
                    value='false'
                    control={<Radio color='primary' />}
                    label='Não'
                    onClick={() => setActiveSync(false)}
                  />
                </RadioGroup>
              }
              name='configuration_value.hinova.active_sync'
              control={control}
              defaultValue={`${association?.configuration_value?.hinova?.active_sync || false}`}
            />
          </Grid>

          <Grid item xs={7}>
            <Controller
              as={<InputComponent disabled={loading || !activeSync} label='Token Hinova' />}
              name='configuration_value.hinova.api_token'
              control={control}
              defaultValue={association?.configuration_value?.hinova?.api_token}
            />
          </Grid>

          <Grid item xs={1} alignItems='center'>
            <Tooltip title='De -> Para'>
              <IconButton onClick={handleToggleModal} disabled={!activeSync}>
                <Settings />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={4}>
            <Controller
              as={<InputComponent disabled={loading} label='Código Cliente Omie' />}
              name='configuration_value.omie.association_code'
              control={control}
              defaultValue={association?.configuration_value?.omie?.association_code}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              as={<InputComponent disabled={loading} label='Conta Inicial Omie' />}
              name='configuration_value.omie.main_account'
              control={control}
              defaultValue={association?.configuration_value?.omie?.main_account}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              as={<InputComponent disabled={loading} label='Conta Finalização Omie' />}
              name='configuration_value.omie.checkout_account'
              control={control}
              defaultValue={association?.configuration_value?.omie?.checkout_account}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              as={<InputComponent disabled={loading} label='Chave PowerBI' />}
              name='configuration_value.association.power_bi'
              control={control}
              defaultValue={association?.configuration_value?.association?.power_bi}
            />
          </Grid>
        </Grid>

        {!associationId && (
          <Grid container spacing={2} alignItems='flex-end'>
            <Grid item xs={4}>
              <Inputfile
                label='Cartão CNPJ'
                accept='image/*,.pdf'
                id='cnpj_card'
                name='cnpj_card'
                onChange={e => handleFile(e)}
                fileName={cpnjCardName}
                disabled={loading}
                clearFile={() => clearFile('cnpj_card')}
              />
            </Grid>

            <Grid item xs={4}>
              <Inputfile
                label='Contrato Social ou Alvará'
                accept='image/*,.pdf'
                id='social_contract'
                name='social_contract'
                onChange={e => handleFile(e)}
                fileName={socialContract}
                disabled={loading}
                clearFile={() => clearFile('social_contract')}
              />
            </Grid>

            <Grid item xs={4}>
              <Inputfile
                label='Comprovante de Residência'
                accept='image/*,.pdf'
                id='proof_address'
                name='proof_address'
                onChange={e => handleFile(e)}
                fileName={proofAddress}
                disabled={loading}
                clearFile={() => clearFile('proof_address')}
              />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} justify='flex-end'>
          <Grid item xs={4}>
            <Button type='submit' filled label={associationId ? 'Atualizar' : 'Cadastrar'} loading={loading} />
          </Grid>
        </Grid>
      </Form>

      <Snackbar message={messageSnack} open={openSnackbar} severity={severity} onClose={() => setOpenSnackbar(false)} />

      <Modal
        open={showModal}
        buttons={[
          <Button key='cancel' type='button' filled label='Cancelar' loading={loading} onClick={handleToggleModal} />,
          <Button key='save' type='button' filled label='Salvar' loading={loading} onClick={handleGetData} />,
        ]}
        fullWidth
      >
        <ConfigList>
          <SortableTree
            style={{ width: '100%', backgroundColor: '#f9f9f9' }}
            treeData={data}
            onChange={treeData => setData(treeData)}
            isVirtualized={false}
            canDrag={false}
            generateNodeProps={({ node, path }) => ({
              title: !node.needsTitle ? (
                node.title
              ) : (
                <InputComponent
                  value={node.title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const title = event.target.value;
                    onChangeInput(node, path, title);
                  }}
                />
              ),
              buttons: [
                <div
                  key={Math.random().toString()}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {node.canHaveChildren && (
                    <IconButton size='small' onClick={() => addQuestion(node, path)}>
                      <Add color='primary' fontSize='small' />
                    </IconButton>
                  )}
                  {!node.header && (
                    <IconButton size='small' onClick={() => removeNode(path)}>
                      <Delete color='primary' fontSize='small' />
                    </IconButton>
                  )}
                </div>,
              ],
              style: {
                height: 50,
              },
            })}
          />
        </ConfigList>
      </Modal>
    </>
  );
}
