import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, Select, MenuItem } from '@material-ui/core';

import {
  ContractorsResponse,
  ContractorBankDataResponse,
  Bank,
  ContractorResponse,
  ContractorResponseEmpty,
} from '~/types';
import {
  DetailsDialog,
  DataTree,
  DataGroup,
  TextDataItem,
  DetailsDialogProps,
  DateDataItem,
  DataItem,
} from '~/components';
import { useContractor } from '~/services/hooks';
import {
  formatCPF,
  formatCNPJ,
  formatZipCode,
  removeFormat,
  formatUsername,
  formatCellPhone,
  onlyNumbers,
} from '~/util/format';
import { validCNPJ, validEmail, isNumeric, validCPF } from '~/util/validate';
import { useBanks } from '~/services/hooks/useBanks';
import { changeContractorApi, deleteContractorApi, signUpContractorApi } from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import { AxiosError } from 'axios';

const BANK_DATA: ContractorBankDataResponse = {
  bank: '',
  branch: '',
  bank_code: '',
  recipient: '',
  account_type: '',
  document_type: '',
  current_account: '',
  billing_address: {
    city: '',
    neighborhood: '',
    number: '',
    state: '',
    street: '',
    zip_code: '',
  },
  pix_key: '',
};

export interface ContractorDetailsDialogProps extends Omit<DetailsDialogProps, 'open' | 'children'> {
  contractor?: ContractorsResponse;
  newContractor?: boolean;
  closeModal?: () => void;
}

type ContractorType = 'ORG_ADMIN' | 'FREELANCER';

// const INITIAL_VALUE: ContractorResponseEmpty = {
//   given_name: 'Adeilson',
//   family_name: 'Silva',
//   id: '36523429091',
//   email: 'adeilson.silva+36523429091@brainweb.com.br',
//   phone_number: '19991076117',
//   rg: { number: '1234564', dispatched_date: '2023-01-13' },
//   cnh: { number: '12345647', expiration_date: '2023-01-13' },
//   organization: {
//     company_name: 'empresa',
//     email: 'adeilson.silva+09253905000148@brainweb.com.br',
//     social_identity: '09253905000148',
//     address: { city: '1', country: '1', neighborhood: '1', number: '1', state: '1', street: '1', zip_code: '13040056' },
//   },
//   bank_data: {
//     bank: 'Banco do Brasil S.A.',
//     branch: '12312',
//     bank_code: '001',
//     recipient: 'd asd as dasd das',
//     account_type: 'CHECKING',
//     document_type: '',
//     current_account: '312312',
//     billing_address: { city: '', neighborhood: '', number: '', state: '', street: '', zip_code: '' },
//     pix_key: '',
//     cpf_cnpj: '36523429091',
//   },
// };

export function ContractorDetailsDialog({
  contractor,
  newContractor,
  closeModal,
  ...props
}: ContractorDetailsDialogProps) {
  const { contractor: details, loading, submitContractor, resetPhone } = useContractor(contractor?.id);
  const { banks, loading: loadingBanks } = useBanks();
  const { addToast } = useToast();

  const [newData, setNewData] = useState<ContractorResponseEmpty>(details ?? ({} as ContractorResponseEmpty));
  const [editing, setEditing] = useState(false);
  const [openResetConfirmation, setOpenResetConfirmation] = useState(false);
  const [contractorType, setContractorType] = useState<ContractorType>('ORG_ADMIN');

  const isFreelancer = contractor?.role === 'FREELANCER' || contractorType === 'FREELANCER';

  const validCPFCNPJ = useCallback(() => {
    if (!newData?.bank_data?.cpf_cnpj || !newData?.bank_data?.cpf_cnpj?.length) {
      return false;
    }

    let userName = removeFormat(newData?.bank_data?.cpf_cnpj);
    if (!userName.trim()) {
      return false;
    }

    if (userName.length <= 11) {
      userName = userName.padStart(11, '0');
      if (!validCPF(userName)) {
        return false;
      }
    } else {
      userName = userName.padStart(14, '0');
      if (!validCNPJ(userName)) {
        return false;
      }
    }

    return true;
  }, [newData]);

  const handleSubmit = useCallback(async () => {
    if (!newData.id) return;

    try {
      if (
        !(
          (!isFreelancer && !newData.organization?.company_name) ||
          (!isFreelancer && !newData.organization?.company_name?.length) ||
          (!isFreelancer && !newData.organization?.email) ||
          (isFreelancer && !validEmail(newData.organization?.email)) ||
          (!isFreelancer && !newData.organization?.social_identity) ||
          (!isFreelancer && !validCNPJ(newData.organization?.social_identity)) ||
          !newData.organization?.address?.street ||
          !newData.organization?.address.street.length ||
          !newData.organization?.address.number ||
          !isNumeric(newData.organization?.address.number) ||
          !newData.organization?.address.neighborhood ||
          !newData.organization?.address.neighborhood.length ||
          !newData.organization?.address.city ||
          !newData.organization?.address.city.length ||
          !newData.organization?.address.state ||
          !newData.organization?.address.state.length ||
          !newData.organization?.address.country ||
          !newData.organization?.address.country.length ||
          !newData.organization?.address.zip_code ||
          newData.organization?.address.zip_code.length !== 8 ||
          (newData.rg?.number.trim() &&
            (!newData.rg.dispatched_date || !/\d{4}-\d{2}-\d{2}/.test(newData.rg.dispatched_date))) ||
          (newData.cnh?.number.trim() &&
            (!newData.cnh?.expiration_date || !/\d{4}-\d{2}-\d{2}/.test(newData.cnh.expiration_date))) ||
          !newData.bank_data?.recipient ||
          !newData.bank_data?.cpf_cnpj ||
          !newData.bank_data?.branch ||
          !newData.bank_data?.current_account ||
          !newData.bank_data?.account_type ||
          !newData.bank_data?.bank_code ||
          !validCPFCNPJ()
        )
      ) {
        const { city, neighborhood, number, state, street, zip_code } = newData.organization.address;
        newData.bank_data.billing_address = { city, neighborhood, number, state, street, zip_code };

        await submitContractor(newData as ContractorResponse, newContractor);

        const contractorData = {
          username: newData.id,
          email: newData.email,
          phone_number: `+55${removeFormat(newData.phone_number?.replace('+55', ''))}`,
          given_name: newData.given_name,
          family_name: newData.family_name,
          locale: 'pt-BR',
          zoneinfo: 'America/Sao_Paulo',
          role: contractorType,
        };

        if (newContractor) {
          await signUpContractorApi(contractorData);
          await changeContractorApi(contractorData);
        } else {
          await changeContractorApi(contractorData);
        }

        setEditing(false);
      }
    } catch (error) {
      const erroAxios: AxiosError = error as any;
      if (
        erroAxios?.response?.data &&
        ([
          'message.customer.phone-number.already.exists',
          'message.sign-up.username.duplicity',
          'message.sign-up.email.duplicity',
        ].includes(erroAxios.response.data) ||
          (Array.isArray(erroAxios.response.data) &&
            ['message.email.invalid.format'].includes(erroAxios.response.data[0]))) &&
        newData.organization?.social_identity
      ) {
        await deleteContractorApi(newData.organization.social_identity);
      }

      const errorMessages = handleApiErrorResponse(
        error,
        newContractor ? 'Erro ao criar o prestador' : 'Erro ao editar o prestador',
      );

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  }, [newData, isFreelancer, validCPFCNPJ, submitContractor, newContractor, contractorType, addToast]);

  const handleChangeName = (value: string) => {
    if (!newData) {
      return;
    }

    const newNameCompany = `${value} ${newData.family_name}`;

    if (isFreelancer) {
      return setNewData({
        ...newData,
        given_name: value,
        organization: { ...newData.organization, company_name: newNameCompany },
      });
    }

    setNewData({ ...newData, given_name: value });
  };

  const handleChangeFamilyName = (value: string) => {
    if (!newData) {
      return;
    }

    const newNameCompany = `${newData.given_name} ${value}`;

    if (isFreelancer) {
      return setNewData({
        ...newData,
        family_name: value,
        organization: { ...newData.organization, company_name: newNameCompany },
      });
    }

    setNewData({ ...newData, family_name: value });
  };

  const handleChangeEmail = (value: string) => {
    if (!newData) {
      return;
    }

    if (isFreelancer) {
      return setNewData({
        ...newData,
        email: value,
        organization: { ...newData.organization, email: value },
      });
    }

    setNewData({ ...newData, email: value });
  };

  const handleChangeId = (value: string) => {
    if (!newData) {
      return;
    }

    if (isFreelancer) {
      return setNewData({
        ...newData,
        id: onlyNumbers(removeFormat(value)),
        organization: { ...newData.organization, social_identity: onlyNumbers(removeFormat(value)) },
      });
    }

    setNewData({ ...newData, id: onlyNumbers(removeFormat(value)) });
  };

  const handleCancelEditing = () => {
    if (newContractor && closeModal) {
      closeModal();
    }

    setEditing(false);
  };

  const handleChangeType = (value: ContractorType) => {
    if (value === 'FREELANCER') {
      const newNameCompany = `${newData.given_name} ${value}`;

      setNewData({
        ...newData,
        organization: {
          ...newData.organization,
          company_name: newNameCompany,
          email: newData.email,
          social_identity: onlyNumbers(removeFormat(newData.id)),
        },
      });
    }

    setContractorType(value);
  };

  useEffect(() => {
    setNewData(details ?? ({} as ContractorResponse));
    // setNewData(INITIAL_VALUE);
    setEditing(!!newContractor);
  }, [details, newContractor]);

  return (
    <DetailsDialog
      open={!!contractor || !!newContractor}
      editable
      editing={editing}
      onRequestEdit={() => setEditing(true)}
      onRequestSave={handleSubmit}
      loading={loading || loadingBanks}
      onCancelEditing={handleCancelEditing}
      resetable={!editing && newData && !newData.organization?.address?.zip_code && !newContractor}
      onRequestReset={() => (resetPhone() as Promise<void>).then(() => setOpenResetConfirmation(true))}
      {...props}
    >
      <>
        <DataTree>
          {newContractor && (
            <DataGroup title='Tipo de prestador'>
              <Select
                labelId='status'
                id='status-select'
                value={contractorType}
                onChange={({ target: { value } }) => handleChangeType(value as ContractorType)}
                disabled={!editing}
              >
                <MenuItem value='ORG_ADMIN'>Empresa</MenuItem>
                <MenuItem value='FREELANCER'>Autônomo</MenuItem>
              </Select>
            </DataGroup>
          )}

          <DataGroup title='Detalhes Pessoais'>
            <TextDataItem title='Nome' value={newData?.given_name} editing={editing} onChange={handleChangeName} />

            <TextDataItem
              title='Sobrenome'
              value={newData?.family_name}
              editing={editing}
              onChange={handleChangeFamilyName}
            />

            <TextDataItem
              title='CPF'
              value={formatCPF(newData?.id)}
              onChange={handleChangeId}
              editing={editing && newContractor}
              otherProps={{ inputProps: { maxLength: 14 } }}
              error={!newData.id || !validCPF(newData.id)}
            />

            <TextDataItem title='E-mail' value={newData?.email} editing={editing} onChange={handleChangeEmail} />

            <TextDataItem
              title='Telefone'
              value={formatCellPhone(removeFormat(newData?.phone_number?.replace('+55', '')))}
              editing={editing}
              onChange={value => setNewData({ ...newData, phone_number: value })}
              otherProps={{ inputProps: { maxLength: 14 } }}
              error={!newData?.phone_number}
            />

            <DataGroup title='RG'>
              <TextDataItem
                title='Número'
                value={newData?.rg?.number}
                onChange={number => setNewData({ ...newData, rg: { dispatched_date: '', ...newData.rg, number } })}
                editing={editing}
              />

              <DateDataItem
                title='Data de Expedição'
                value={newData?.rg?.dispatched_date}
                onChange={dispatched_date =>
                  setNewData({ ...newData, rg: { number: '', ...newData.rg, dispatched_date } })
                }
                editing={editing}
                error={
                  !!newData.rg?.number &&
                  (!newData.rg?.dispatched_date || !/\d{4}-\d{2}-\d{2}/.test(newData.rg.dispatched_date))
                }
              />
            </DataGroup>

            <DataGroup title='CNH'>
              <TextDataItem
                title='Número'
                value={newData?.cnh?.number}
                onChange={number => setNewData({ ...newData, cnh: { expiration_date: '', ...newData.cnh, number } })}
                editing={editing}
              />

              <DateDataItem
                title='Data de Expiração'
                value={newData?.cnh?.expiration_date}
                onChange={expiration_date =>
                  setNewData({ ...newData, cnh: { number: '', ...newData.cnh, expiration_date } })
                }
                editing={editing}
                error={
                  !!newData.cnh?.number &&
                  (!newData.cnh?.expiration_date || !/\d{4}-\d{2}-\d{2}/.test(newData.cnh.expiration_date))
                }
              />
            </DataGroup>
          </DataGroup>

          {!isFreelancer && (
            <DataGroup title='Detalhes da Empresa'>
              <TextDataItem
                title='Nome'
                value={newData?.organization?.company_name}
                onChange={company_name =>
                  setNewData({ ...newData, organization: { ...newData.organization, company_name } })
                }
                editing={editing}
                error={!newData?.organization?.company_name || !newData.organization.company_name.length}
              />

              <TextDataItem
                title='E-mail'
                value={newData?.organization?.email}
                onChange={email => setNewData({ ...newData, organization: { ...newData.organization, email } })}
                editing={editing}
                error={!newData?.organization?.email || !validEmail(newData.organization.email)}
              />

              <TextDataItem
                title='CNPJ'
                value={formatCNPJ(newData?.organization?.social_identity)}
                onChange={social_identity =>
                  setNewData({
                    ...newData,
                    organization: {
                      ...newData.organization,
                      social_identity: onlyNumbers(removeFormat(social_identity)),
                    },
                  })
                }
                editing={editing}
                error={!newData?.organization?.social_identity || !validCNPJ(newData.organization.social_identity)}
                otherProps={{ inputProps: { maxLength: 18 } }}
              />
            </DataGroup>
          )}

          <DataGroup title='Detalhes de Endereço'>
            <TextDataItem
              title='Rua'
              value={newData?.organization?.address?.street}
              onChange={street =>
                setNewData({
                  ...newData,
                  organization: { ...newData.organization, address: { ...newData.organization?.address, street } },
                })
              }
              editing={editing}
              error={!newData?.organization?.address?.street || !newData.organization.address.street.length}
            />

            <TextDataItem
              title='Número'
              value={newData?.organization?.address?.number}
              onChange={number =>
                setNewData({
                  ...newData,
                  organization: { ...newData.organization, address: { ...newData.organization?.address, number } },
                })
              }
              editing={editing}
              error={!newData?.organization?.address?.number || !isNumeric(newData.organization.address.number)}
            />

            <TextDataItem
              title='Complemento'
              value={newData?.organization?.address?.complement}
              onChange={complement =>
                setNewData({
                  ...newData,
                  organization: { ...newData.organization, address: { ...newData.organization?.address, complement } },
                })
              }
              editing={editing}
            />

            <TextDataItem
              title='Bairro'
              value={newData?.organization?.address?.neighborhood}
              onChange={neighborhood =>
                setNewData({
                  ...newData,
                  organization: {
                    ...newData.organization,
                    address: { ...newData.organization?.address, neighborhood },
                  },
                })
              }
              editing={editing}
              error={!newData?.organization?.address?.neighborhood || !newData.organization.address.neighborhood.length}
            />

            <TextDataItem
              title='Cidade'
              value={newData?.organization?.address?.city}
              onChange={city =>
                setNewData({
                  ...newData,
                  organization: { ...newData.organization, address: { ...newData.organization?.address, city } },
                })
              }
              editing={editing}
              error={!newData?.organization?.address?.city || !newData.organization.address.city.length}
            />

            <TextDataItem
              title='Estado'
              value={newData?.organization?.address?.state}
              onChange={state =>
                setNewData({
                  ...newData,
                  organization: { ...newData.organization, address: { ...newData.organization?.address, state } },
                })
              }
              editing={editing}
              error={!newData?.organization?.address?.state || !newData.organization.address.state.length}
            />

            <TextDataItem
              title='País'
              value={newData?.organization?.address?.country}
              onChange={country =>
                setNewData({
                  ...newData,
                  organization: { ...newData.organization, address: { ...newData.organization?.address, country } },
                })
              }
              editing={editing}
              error={!newData?.organization?.address?.country || !newData.organization.address.country.length}
            />

            <TextDataItem
              title='CEP'
              value={formatZipCode(newData?.organization?.address?.zip_code)}
              onChange={zip_code =>
                setNewData({
                  ...newData,
                  organization: {
                    ...newData.organization,
                    address: { ...newData.organization?.address, zip_code: removeFormat(zip_code, 8) },
                  },
                })
              }
              editing={editing}
              error={!newData?.organization?.address?.zip_code || newData.organization.address.zip_code.length !== 8}
            />
          </DataGroup>

          <DataGroup title='Dados bancários'>
            <TextDataItem
              title='Nome do titular da conta'
              value={newData?.bank_data?.recipient}
              onChange={recipient => {
                setNewData({
                  ...newData,
                  bank_data: { ...BANK_DATA, ...newData.bank_data, recipient },
                });
              }}
              editing={editing}
              error={!newData?.bank_data?.recipient || !newData.bank_data?.recipient?.length}
            />

            <TextDataItem
              title='CPF / CNPJ do titular da conta'
              value={formatUsername(newData?.bank_data?.cpf_cnpj)}
              onChange={cpf_cnpj => {
                setNewData({
                  ...newData,
                  bank_data: { ...BANK_DATA, ...newData.bank_data, cpf_cnpj: removeFormat(cpf_cnpj) },
                });
              }}
              editing={editing}
              error={!validCPFCNPJ()}
            />

            <DataItem title='Banco'>
              <Select
                value={JSON.stringify({ code: newData?.bank_data?.bank_code, bank: newData?.bank_data?.bank })}
                onChange={({ target: { value } }) => {
                  const bank: Bank = JSON.parse(String(value));

                  setNewData({
                    ...newData,
                    bank_data: { ...BANK_DATA, ...newData.bank_data, bank_code: bank.code, bank: bank.bank },
                  });
                }}
                disabled={!editing}
              >
                {banks?.map(bank => (
                  <MenuItem key={bank.code} value={JSON.stringify(bank)}>
                    {`${bank.code} - ${bank.bank}`}
                  </MenuItem>
                ))}
              </Select>
            </DataItem>

            <TextDataItem
              title='Agência'
              value={newData?.bank_data?.branch}
              onChange={branch => {
                setNewData({
                  ...newData,
                  bank_data: { ...BANK_DATA, ...newData.bank_data, branch },
                });
              }}
              editing={editing}
              error={!newData?.bank_data?.branch || !newData.bank_data?.branch?.length}
            />

            <TextDataItem
              title='Número da conta'
              value={newData?.bank_data?.current_account}
              onChange={current_account => {
                setNewData({
                  ...newData,
                  bank_data: { ...BANK_DATA, ...newData.bank_data, current_account },
                });
              }}
              editing={editing}
              error={!newData?.bank_data?.current_account || !newData.bank_data?.current_account?.length}
            />

            <DataItem title='Tipo de conta'>
              <Select
                value={newData?.bank_data?.account_type}
                onChange={({ target: { value } }) => {
                  const account_type = String(value);
                  setNewData({
                    ...newData,
                    bank_data: { ...BANK_DATA, ...newData.bank_data, account_type },
                  });
                }}
                disabled={!editing}
              >
                <MenuItem value='CHECKING'>Conta corrente</MenuItem>
                <MenuItem value='SAVINGS'>Conta poupança</MenuItem>
              </Select>
            </DataItem>

            <TextDataItem
              title='Chave PIX'
              value={newData?.bank_data?.pix_key}
              onChange={pix_key => {
                setNewData({
                  ...newData,
                  bank_data: { ...BANK_DATA, ...newData.bank_data, pix_key },
                });
              }}
              editing={editing}
            />
          </DataGroup>
        </DataTree>

        <Dialog open={openResetConfirmation} onClose={() => setOpenResetConfirmation(false)}>
          <DialogTitle>Conta Redefinida com Sucesso!</DialogTitle>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={() => setOpenResetConfirmation(false)}>
              Obrigado
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </DetailsDialog>
  );
}
