import React, { useCallback, useMemo, useState } from 'react';
import {
  AppBar,
  createStyles,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  makeStyles,
  Slide,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close, History } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAddresses, useQuizAnswers } from '~/services/hooks';
import useOrderCosts from '~/services/hooks/useOrderCosts';
import { useOrderDetails } from '~/services/hooks/useOrderDetails';
import useOrderDocuments from '~/services/hooks/useOrderDocuments';

import { formatUsername, formatPrice } from '~/util/format';

import { CardsBox } from './styles';
import { Action, Card, Item, NoteItem, PhotosCard } from './components';

import {
  CheckinDocumentType,
  CheckoutDocumentType,
  CostType,
  CustomRoles,
  OpportunitiesResponse,
  PayerType,
} from '~/types';
import { IconWrapper, OpenOnMapsIcon } from './components/Card/styles';
import { AddNoteButton } from './components/AddNoteButton';
import { AddNoteModal } from './components/AddNoteModal';
import { AddPhotoButton } from '../UploadImage/components/InputButton';
import { DropzoneModal } from '../UploadImage/components/DropzoneModal';
import usePaymentDetails from '~/services/hooks/usePaymentDetails';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    gridList: {
      padding: 30,
      transform: 'translateZ(0)',
    },
  }),
);

export interface DetailsProps {
  opportunity?: OpportunitiesResponse[number];
  onRequestClose: () => void;
}

export function Details(props: DetailsProps) {
  const classes = useStyles();
  const [addNote, setAddNote] = useState(false);
  const [dropZone, setDropZone] = useState(false);
  const { lastInsertion, customRole } = useSelector(state => ({
    lastInsertion: state.uploadPhotos.lastInsertion,
    customRole: state.auth.user['custom:role'],
  }));

  const addresses = useAddresses(props.opportunity);
  const quizAnswers = useQuizAnswers(props.opportunity?.id);
  const documents = useOrderDocuments(props.opportunity?.id, lastInsertion);
  const details = useOrderDetails(props.opportunity?.id);
  const costs = useOrderCosts(props.opportunity?.id);
  const [dropZoneType, setDropZoneType] = useState('');
  const history = useHistory();
  const paymentDetails = usePaymentDetails(props.opportunity?.id, props.opportunity?.payment_type);

  const handleAddNote = () => {
    setAddNote(true);
  };

  const handleCloseAddNote = () => {
    setAddNote(false);
  };

  const handleAddPhoto = (type: string) => {
    setDropZoneType(type);
    setDropZone(true);
  };

  const handleCloseDropZone = () => {
    setDropZone(false);
  };

  const opportunityData = useMemo(() => {
    const opportunities: [string, string | undefined, boolean, boolean][] = [
      ['Número:', props.opportunity?.number, true, true],
      ['ID:', props.opportunity?.id, true, true],
      ['Origem:', props.opportunity?.issued_by, false, true],
      ['Criada Em:', parseDate(props.opportunity?.created_at), false, true],
      ['Ultima Atualização:', parseDate(props.opportunity?.updated_at), false, true],
      ['Situação:', props.opportunity?.status, false, true],
    ];

    if (props.opportunity?.cancel_reason) {
      opportunities.push(['Motivo do cancelamento:', props.opportunity?.cancel_reason, false, true]);
    }

    opportunities.push([
      'Auto Alocação Desabilitada?',
      props.opportunity?.disable_auto_allocation ? 'Sim' : 'Não',
      false,
      false,
    ]);

    return opportunities;
  }, [props.opportunity]);

  const handleHistory = useCallback(() => {
    if (!props.opportunity?.id) {
      return;
    }

    history.push(`/opportunity-timeline/${props.opportunity.id}`);
  }, [history, props.opportunity]);

  const getCoordinate = useCallback(
    (tag: 'SOURCE' | 'DESTINATION') => {
      return props.opportunity?.coordinates.find(coordinate => coordinate.position === tag);
    },
    [props.opportunity],
  );

  const getAnnotations = () => {
    return props.opportunity?.backoffice_note && props.opportunity?.backoffice_note.length > 0
      ? JSON.parse(props.opportunity?.backoffice_note).map(item => {
          return (
            <>
              <NoteItem field='Observação:' content={item} code />
              <Divider style={{ margin: 5 }} />
            </>
          );
        })
      : undefined;
  };

  return (
    <>
      <Dialog fullScreen open={!!props.opportunity} onClose={props.onRequestClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={props.onRequestClose} aria-label='close'>
              <Close />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              {`Detalhes (${props.opportunity?.number})`}

              <IconButton
                edge='start'
                color='inherit'
                onClick={handleHistory}
                aria-label='close'
                style={{ marginLeft: 20 }}
              >
                <History />
              </IconButton>
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <CardsBox>
            <Card title='Oportunidade'>
              {opportunityData.map(([title, value, code, copyable], index) => (
                <Item key={`${index}`} field={title} code={code} copyable={copyable}>
                  {value}
                </Item>
              ))}
            </Card>

            <Card title='Serviço'>
              <Item field='ID:' content={props.opportunity?.service_id} code copyable />
              <Item field='Descrição:' content={props.opportunity?.service_description} copyable />
            </Card>
          </CardsBox>

          <CardsBox>
            <Card title='Cliente'>
              <Item field='CPF/CNPJ:' content={formatUsername(props.opportunity?.customer_id)} copyable />
              <Item field='Nome:' content={props.opportunity?.customer_name} copyable />
              <Item
                field='Telefone Contato:'
                content={quizAnswers?.value?.find(v => v.question === 'Telefone de contato')?.answer}
                copyable
              />
              <Item field='Avaliação:' content={props.opportunity?.contractor_survey?.rating} copyable />
              <Item field='Justificativa:' content={props.opportunity?.contractor_survey?.feedback} copyable />
            </Card>

            {customRole === CustomRoles.B2B ? (
              <Card title='Prestador'>
                <Item field='Nome:' content={props.opportunity?.contractor_name?.split(' ')[0]} copyable />
                <Item field='Avaliação:' content={props.opportunity?.customer_survey?.rating} copyable />
              </Card>
            ) : (
              <Card title='Prestador'>
                <Item field='CPF/CNPJ:' content={formatUsername(props.opportunity?.contractor_id)} copyable />
                <Item field='Nome:' content={props.opportunity?.contractor_name} copyable />
                <Item field='Telefone:' content={props.opportunity?.contractor_phone_number} copyable />
                <Item field='Avaliação:' content={props.opportunity?.customer_survey?.rating} copyable />
                <Item field='Justificativa:' content={props.opportunity?.customer_survey?.feedback} copyable />
              </Card>
            )}
          </CardsBox>

          <CardsBox>
            <Card title='Empresa'>
              <Item field='ID:' content={props.opportunity?.association_id} code copyable />
              <Item field='Nome:' content={props.opportunity?.association_name} copyable />
            </Card>

            <Card
              copyable
              title='Quiz'
              data={[
                ...(quizAnswers?.value
                  ? quizAnswers.value.map(
                      v => [v.question, v.answer + ` (bloqueia: ${v.block ? 'Sim' : 'Não'})`] as [string, string],
                    )
                  : []),
              ]}
            />
          </CardsBox>

          <CardsBox>
            <Card title='Origem' copyable>
              {!!getCoordinate('SOURCE') && (
                <Action tootipText='Abrir no Google Maps'>
                  <GoogleMapsIcon
                    latitude={getCoordinate('SOURCE')?.latitude}
                    longitude={getCoordinate('SOURCE')?.longitude}
                  />
                </Action>
              )}

              <Item field='Endereço:' content={addresses[0]?.street_number} />
              <Item field='Bairro:' content={addresses[0]?.neighborhood} />
              <Item field='Cidade:' content={addresses[0]?.city_state} />
            </Card>

            <Card title='Destino' copyable>
              {!!getCoordinate('DESTINATION') && (
                <Action tootipText='Abrir no Google Maps'>
                  <GoogleMapsIcon
                    latitude={getCoordinate('DESTINATION')?.latitude}
                    longitude={getCoordinate('DESTINATION')?.longitude}
                  />
                </Action>
              )}
              <Item field='Endereço:' content={addresses[1]?.street_number} />
              <Item field='Bairro:' content={addresses[1]?.neighborhood} />
              <Item field='Cidade:' content={addresses[1]?.city_state} />
            </Card>
          </CardsBox>

          <CardsBox>
            <Card title='Veículo do Cliente' copyable>
              <Item field='ID:' content={props.opportunity?.vehicle_id} code />
              <Item field='Tipo:' content={props.opportunity?.customer_vehicle_type} />
              <Item field='Marca:' content={props.opportunity?.vehicle_manufacture} />
              <Item field='Modelo:' content={props.opportunity?.vehicle_model} />
              <Item field='Ano:' content={props.opportunity?.vehicle_model_year} />
              <Item field='Placa:' content={props.opportunity?.license_plate} />
              <Item field='Cor:' content={props.opportunity?.vehicle_color} />
              <Item field='Estado:' content={props.opportunity?.state} />
              <Item field='Cidade:' content={props.opportunity?.city} />
              <Item field='Situação:' content={props.opportunity?.vehicle_status} />
            </Card>

            <Card title='Pagamento'>
              <Item field='ID:' content={props.opportunity?.payment_id} code copyable />
              <Item field='Token:' content={props.opportunity?.payment_token} code copyable />
              <Item field='Tipo:' content={props.opportunity?.payment_type} />
              {paymentDetails?.pix?.qrcode_text && (
                <Item field='Chave PIX:' content={paymentDetails.pix.qrcode_text} copyable hasWhiteSpace={false} />
              )}
              {!!paymentDetails?.secure_url && (
                <Item field='Fatura:' content={paymentDetails.secure_url} copyable hasWhiteSpace={false} />
              )}
              <Item field='Valor Total:' content={`R$ ${props.opportunity?.total.toString().replace('.', ',')}`} />
              <Item field='Desconto:' content={`${props.opportunity?.discount}`} />
              <Item field='Extra:' content={`${props.opportunity?.surcharge}`} />
              <Item field='Tem Benefício?' content={props.opportunity?.has_benefits ? 'Sim' : 'Não'} />
              <Item
                field='Excedeu a Cota de Benefício?'
                content={props.opportunity?.benefits_quota_exceeded ? 'Sim' : 'Não'}
              />
              <Item field='Consumido:' content={props.opportunity?.expended ? 'Sim' : 'Não'} />
            </Card>
          </CardsBox>

          <CardsBox>
            <PhotosCard
              title='Fotos do Início do Serviço'
              data={[
                ...documents
                  .filter(d => d.document_type.match('CHECKIN'))
                  .map(
                    d =>
                      [
                        {
                          CHECKIN_IMG_BOTTOM: 'FUNDO',
                          CHECKIN_IMG_FRONT: 'FRENTE',
                          CHECKIN_IMG_LEFT_SIDE: 'LATERAL ESQUERDA',
                          CHECKIN_IMG_RIGHT_SIDE: 'LATERAL DIREITA',
                          CHECKIN_IMG_WINCHED: 'VEÍCULO GUINCHADO',
                        }[d.document_type as CheckinDocumentType],
                        d.value.replace('https', 'http'),
                      ] as [string, string],
                  ),
              ]}
              details={details}
              type='SKIP_CHECKIN_PICTURES'
              button={<AddPhotoButton onClick={() => handleAddPhoto('CHECKIN')} />}
            />

            <PhotosCard
              title='Fotos da Conclusão do Serviço'
              data={[
                ...documents
                  .filter(d => d.document_type.match('CHECKOUT'))
                  .map(
                    d =>
                      [
                        {
                          CHECKOUT_IMG_BOTTOM: 'FUNDO',
                          CHECKOUT_IMG_FRONT: 'FRENTE',
                          CHECKOUT_IMG_LEFT_SIDE: 'LATERAL ESQUERDA',
                          CHECKOUT_IMG_RIGHT_SIDE: 'LATERAL DIREITA',
                          CHECKOUT_IMG_WINCHED: 'VEÍCULO GUINCHADO',
                        }[d.document_type as CheckoutDocumentType],
                        d.value.replace('https', 'http'),
                      ] as [string, string],
                  ),
              ]}
              details={details}
              type='SKIP_CHECKOUT_PICTURES'
              button={<AddPhotoButton onClick={() => handleAddPhoto('CHECKOUT')} />}
            />
          </CardsBox>

          <CardsBox>
            <Card
              title='Custos'
              copyable
              data={(() => {
                const ret: [string, string?, boolean?][] = [];
                const newCosts = costs.filter(
                  item =>
                    (customRole === CustomRoles.B2B && item.type !== 'COMMISSION_PERCENTAGE') ||
                    customRole !== CustomRoles.B2B,
                );
                const costsLength = newCosts.length - 1;

                newCosts.forEach((cost, index) => {
                  ret.push([
                    {
                      CANCELLATION_FEE: 'Taxa de cancelamento:',
                      SPECIAL_EXIT: 'Saída especial:',
                      STOP_TIME: 'Tempo parado:',
                      TOLL: 'Pedágio:',
                      OUTPUT_LIMIT: 'Saída:',
                      OVER_DISTANCE: 'Excedido:',
                      COMMISSION_PERCENTAGE: 'Comissão:',
                    }[cost.type as CostType],
                    `${formatPrice(cost.value)}${cost.type === 'STOP_TIME' ? ' / segundo' : ''}`,
                  ] as [string, string]);
                  ret.push(['Pagador:', { ASSOCIATION: 'Empresa', CUSTOMER: 'Cliente' }[cost.payer as PayerType]]);
                  ret.push(['Quantidade:', `${cost.amount}${cost.type === 'STOP_TIME' ? ' segundo(s)' : ''}`]);
                  ret.push(['Multiplicador:', cost.multiplier, index !== costsLength]);
                });

                return ret;
              })()}
            />
            {customRole !== CustomRoles.B2B && (
              <Card
                title='Observações'
                copyable
                buttonHeader={<AddNoteButton onClick={handleAddNote} />}
                annotation={
                  props.opportunity?.backoffice_note && props.opportunity?.backoffice_note.length > 0
                    ? JSON.parse(props.opportunity?.backoffice_note).map(item => {
                        return { field: 'Observação', data: item };
                      })
                    : null
                }
                optionalChildren={getAnnotations()}
              />
            )}
          </CardsBox>
        </DialogContent>
      </Dialog>

      <AddNoteModal
        opportunityNotes={
          props.opportunity?.backoffice_note && props.opportunity?.backoffice_note.length > 0
            ? JSON.parse(props.opportunity?.backoffice_note)
            : []
        }
        visible={addNote}
        opportunityId={props.opportunity?.id}
        onClose={handleCloseAddNote}
        opportunityStatus={props.opportunity?.status}
      />
      <DropzoneModal
        type={dropZoneType}
        visible={dropZone}
        opportunityId={props.opportunity?.id}
        onClose={handleCloseDropZone}
      />
    </>
  );
}

function parseDate(dateNumber?: number) {
  if (!dateNumber) return '';
  const date = new Date(dateNumber);
  return `${format(date.getDate())}/${format(date.getMonth() + 1)}/${format(date.getFullYear())} ${format(
    date.getHours(),
  )}:${format(date.getMinutes())}`;
}

function format(dateFrag: string | number) {
  return ('0' + dateFrag).slice(-2);
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function GoogleMapsIcon({ latitude, longitude }) {
  return (
    <IconWrapper>
      <a href={`https://www.google.com/maps?q=${latitude},${longitude}`} target='_blank' rel='noopener noreferrer'>
        <OpenOnMapsIcon fontSize='large' />
      </a>
    </IconWrapper>
  );
}

export default Details;
