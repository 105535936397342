import React, { useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Info, Edit } from '@material-ui/icons';

import Table from '~/components/Table';
import { useAssociations } from '~/services/hooks/useAssociations';
import { Association } from '~/types';
import DetailsDialog from '~/components/DetailsDialog';
import DataTree, { DataGroup, TextDataItem } from '~/components/DataTree';
import { Title } from '~/components/Title';

interface AssociationManagerParams {}

interface AssociationManagerLocationState {}

type AssociationManagerProps = RouteChildrenProps<AssociationManagerParams, AssociationManagerLocationState>;

export function AssociationManager(props: AssociationManagerProps) {
  const { associations, loading } = useAssociations();

  const [association, setAssociation] = useState<Association>();

  const handleEditAssociation = (data: Association | Association[]) => {
    const associationEditing = Array.isArray(data) ? data[0] : data;
    props.history.push(`/association/${associationEditing.id}`);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
        <Title style={{ flex: 1 }}>Gestão de Empresas</Title>
        <Button
          variant='contained'
          color='secondary'
          style={{ textTransform: 'none', padding: '10px 15px' }}
          onClick={() => props.history.push('/association')}
        >
          Cadastrar
        </Button>
      </div>

      <Table
        columns={{
          code: 'Código',
          company_name: 'Razão Social',
          trading_name: 'Nome Fantasia',
          pohone_number: 'Telefone',
          email: 'Email',
          'address_response.city': 'Cidade',
          'address_response.neighborhood': 'Bairro',
        }}
        data={associations}
        actions={[
          {
            icon: () => <Info />,
            tooltip: 'detalhes',
            onClick: (event, data) => setAssociation(data as Association),
          },
          {
            icon: () => <Edit />,
            tooltip: 'Editar',
            onClick: (_, data) => handleEditAssociation(data),
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: 'Sem empresas',
          },
          pagination: {
            labelRowsSelect: 'empresas',
          },
          header: {
            actions: '',
          },
        }}
        options={{
          showTitle: false,
        }}
        isLoading={loading}
      />

      <DetailsDialog open={!!association} onClose={() => setAssociation(undefined)}>
        <DataTree>
          <DataGroup title='Detalhes da Empresa'>
            <TextDataItem title='ID' value={association?.id} />
            <TextDataItem title='Cobrança pelo excedente' value={association?.charge_excess ? 'Sim' : 'Não'} />
            <TextDataItem
              title='Serviços com Origem e Destino diferentes?'
              value={association?.diff_source_destination ? 'Sim' : 'Não'}
            />
          </DataGroup>
        </DataTree>
      </DetailsDialog>
    </>
  );
}

// function parseAssociationData(a?: Association): Data[] | undefined {
//   if (!a) return undefined
//   else return (([
//     [
//       'Detalhes da Empresa',
//       [
//         ['ID', a.id],
//         ['Razão Social', a.company_name],
//         ['Nome Fantasia', a.trading_name],
//         ['Descrição', a.description],
//         ['Telefone', a.phone_number],
//         ['Email', a.email],
//         ['Código', a.code],
//       ].map(b => ({ info: b[0], text: b[1] }))
//     ],
//     [
//       'Endereço',
//       [
//         ['ID', a.address_response.id],
//         ['Rua', a.address_response.street],
//         ['Número', a.address_response.number],
//         ['Complemento', a.address_response.complement],
//         ['Bairro', a.address_response.neighborhood],
//         ['CEP', a.address_response.zip_code],
//         ['Cidade', a.address_response.city],
//         ['Estado', a.address_response.state],
//         ['País', a.address_response.country],
//       ].map(b => ({ info: b[0], text: b[1] }))
//     ]
//   ] as [string, InfoData[]][]).map(c => ({ title: c[0], data: c[1] })))
// }

export default AssociationManager;
