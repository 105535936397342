import React, { useState, useEffect, useCallback } from 'react';
import { Select, MenuItem, FormGroup, FormControlLabel, Checkbox, TextField, FormControl } from '@material-ui/core';

import {
  VehicleContractorRequestEmpty,
  VehicleContractorRequest,
  VehiclesResponseItem,
  VehiclesResponseItemEmpty,
  ContractorsResponse,
  VehicleServicesResponse,
} from '~/types';
import { DetailsDialog, DataTree, DataGroup, TextDataItem, DetailsDialogProps, DataItem } from '~/components';
import {
  createVehicleApi,
  getVehicleServicesByTypeIDApi,
  loadVehicleServicesApi,
  updateVehicleApi,
} from '~/services/api';
import { handleApiErrorResponse } from '~/services/handleErrors';
import { useToast } from '~/services/hooks/toast';
import { CategoryType, ServiceType, useFipeContractor } from '~/services/hooks/useFipeContractor';
import { CustomAutocomplete } from '~/components/CustomAutocomplete';
import { ManufacturerType, ModelType, VehicleType } from '~/services/hooks/useFipe';
import { formatLicensePlate } from '~/util/format';

export interface ContractorDetailsDialogProps extends Omit<DetailsDialogProps, 'open' | 'children'> {
  vehicleItem?: VehiclesResponseItem;
  contractor: ContractorsResponse;
  newVehicle?: boolean;
}

interface SelectedServicesType {
  [key: string]: boolean;
}

// const INITIAL_VALUE: VehiclesResponseItemEmpty = {
//   city: 'CAMPINAS',
//   color: 'PRETA',
//   license_plate: 'ADE-1233',
//   manufacture: 'ADLY',
//   model: 'ATV 100',
//   state: 'SP',
//   vin: 'VINDETESTE',
// };

const getCategoryName = (category?: string): 'CAR' | 'MOTORCYCLE' | 'TRUCK' => {
  const categoryName = {
    'VEÍCULO COMUM': 'CAR',
    MOTOCICLETA: 'MOTORCYCLE',
  };
  return categoryName[category ?? ''] ?? 'TRUCK';
};

export function VehicleDetailsDialog({
  vehicleItem,
  contractor,
  newVehicle,
  onClose,
  ...props
}: ContractorDetailsDialogProps) {
  const { addToast } = useToast();

  const [newData, setNewData] = useState<VehiclesResponseItemEmpty>(vehicleItem ?? ({} as VehiclesResponseItemEmpty));
  const [selectedCategoryType, setSelectedCategoryType] = useState<CategoryType>();
  const [selectedCategoryTypeId, setSelectedCategoryTypeId] = useState<string>();
  const [selectedSubCategoryTypeId, setSelectedSubCategoryTypeId] = useState<string>();
  const [servicesType, setServicesType] = useState<ServiceType[]>();
  const [selectedServicesType, setSelectedServicesType] = useState<SelectedServicesType>();
  const [manufactorSelected, setManufactorSelected] = useState<ManufacturerType | null>();
  const [vehicleSelected, setVehicleSelected] = useState<VehicleType | null>();
  const [modelSelected, setModelSelected] = useState<ModelType | null>();

  const {
    contractorCategoryTypes,
    getManufacturers,
    loadingManufacturer,
    manufacturerTypes,
    getVehicles,
    loadingVehicles,
    vehiclesTypes,
    getModels,
    loadingModels,
    modelsTypes,
  } = useFipeContractor();

  const handleCloseModal = useCallback(() => {
    setModelSelected(undefined);
    setVehicleSelected(undefined);
    setManufactorSelected(undefined);
    setSelectedServicesType(undefined);
    setSelectedCategoryType(undefined);
    setSelectedCategoryTypeId(undefined);
    setNewData({});
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    if (!newData.license_plate) return;

    try {
      if (
        !newData.city ||
        !newData.color ||
        !newData.license_plate ||
        !newData.state ||
        !newData.vin ||
        !manufactorSelected ||
        !vehicleSelected ||
        !servicesType ||
        !selectedCategoryTypeId ||
        !modelSelected
      ) {
        return;
      }

      const services = Object.entries(selectedServicesType ?? {})
        .filter(([, value]) => value)
        .map(([service_id]) => ({ service_id }));

      const fipe_data = {
        model_id: vehicleSelected.id,
        manufacture_id: manufactorSelected.id,
        model_year_id: modelSelected.id,
      };

      const data: VehicleContractorRequest = {
        organization_id: contractor.organization_id,
        city: newData.city.toUpperCase(),
        color: newData.color.toUpperCase(),
        license_plate: newData.license_plate,
        manufacture: manufactorSelected.name.toUpperCase(),
        model: vehicleSelected.name.toUpperCase(),
        services,
        vehicle_contractor_type_id: selectedSubCategoryTypeId ?? selectedCategoryTypeId,
        state: newData.state.toUpperCase(),
        vin: newData.vin,
        model_year: modelSelected.name,
        status: 'ATIVO',
        fipe_data: JSON.stringify(fipe_data),
      };

      vehicleItem ? await updateVehicleApi(vehicleItem.id, data) : await createVehicleApi(data);
      handleCloseModal();
    } catch (error) {
      const errorMessages = handleApiErrorResponse(
        error,
        newVehicle ? 'Erro ao criar o veículo' : 'Erro ao editar o veículo',
      );

      addToast({
        title: 'Erro Inesperado',
        type: 'error',
        description: errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
      });
    }
  }, [
    newData.license_plate,
    newData.city,
    newData.color,
    newData.state,
    newData.vin,
    manufactorSelected,
    vehicleSelected,
    servicesType,
    selectedCategoryTypeId,
    modelSelected,
    selectedServicesType,
    contractor.organization_id,
    selectedSubCategoryTypeId,
    vehicleItem,
    handleCloseModal,
    newVehicle,
    addToast,
  ]);

  const handleChangeField = (field: keyof VehicleContractorRequestEmpty) => (value: string) => {
    if (field === 'license_plate') {
      return setNewData({ ...newData, [field]: formatLicensePlate(value) });
    }

    setNewData({ ...newData, [field]: value.toUpperCase() });
  };

  const handleChangeType = useCallback(
    (value: string) => {
      const founded =
        contractorCategoryTypes?.find(item => item.id === value) ??
        contractorCategoryTypes
          ?.filter(item => !!item.subtypes)
          .find(item => item.subtypes?.find(item => item.id === value));
      const subtypeFounded = founded?.subtypes?.find(item => item.id === value);

      setServicesType(undefined);
      setSelectedServicesType(undefined);

      setSelectedCategoryTypeId(founded?.id);
      setSelectedSubCategoryTypeId(subtypeFounded?.id);
      setSelectedCategoryType(founded);
    },
    [contractorCategoryTypes],
  );

  const handleChangeSubType = (value: string) => {
    const founded = contractorCategoryTypes?.find(item => item.id === selectedCategoryType?.id);
    if (!founded) {
      return;
    }

    const foundedSub = founded.subtypes?.find(item => item.id === value);

    if (!foundedSub) {
      return;
    }

    setSelectedCategoryTypeId(foundedSub.id);
  };

  const handleChangeService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedServicesType(prev => ({
      ...prev,
      [event.target.value]: event.target.checked,
    }));
  };

  useEffect(() => {
    setNewData(vehicleItem ?? ({} as VehiclesResponseItem));
    // setNewData(INITIAL_VALUE);
  }, [vehicleItem]);

  useEffect(() => {
    const getVehicleServicesByTypeID = async () => {
      try {
        if (!selectedCategoryTypeId) {
          setServicesType(undefined);
          return;
        }

        const { data } = await getVehicleServicesByTypeIDApi(selectedSubCategoryTypeId ?? selectedCategoryTypeId);
        setServicesType(data);
      } catch (error) {
        const errorMessages = handleApiErrorResponse(error, 'Erro ao consultar serviços');

        addToast({
          title: 'Erro Inesperado',
          type: 'error',
          description:
            errorMessages?.handledMessage || errorMessages?.apiError || errorMessages?.applicationErrorMessage,
        });
      }
    };

    getVehicleServicesByTypeID();
  }, [addToast, selectedCategoryTypeId, selectedSubCategoryTypeId]);

  useEffect(() => {
    if (!servicesType) {
      return;
    }

    const category = getCategoryName(selectedCategoryType?.description);

    getManufacturers(category);
  }, [getManufacturers, selectedCategoryType, servicesType]);

  useEffect(() => {
    if (!servicesType || !manufactorSelected) {
      return;
    }

    const category = getCategoryName(selectedCategoryType?.description);

    getVehicles(category, manufactorSelected.id);
  }, [getVehicles, manufactorSelected, selectedCategoryType, servicesType]);

  useEffect(() => {
    if (!servicesType || !manufactorSelected || !vehicleSelected) {
      return;
    }

    const category = getCategoryName(selectedCategoryType?.description);

    getModels(category, manufactorSelected.id, vehicleSelected.id);
  }, [getModels, manufactorSelected, selectedCategoryType, servicesType, vehicleSelected]);

  useEffect(() => {
    const loadData = async () => {
      if (!contractorCategoryTypes || !vehicleItem?.vehicle_contractor_type_id) {
        return;
      }

      handleChangeType(vehicleItem.vehicle_contractor_type_id);

      if (vehicleItem.id) {
        const { data } = (await loadVehicleServicesApi(vehicleItem.id)) as { data: VehicleServicesResponse };
        const services = data.reduce((acc, item) => {
          acc[item.service_id] = true;
          return acc;
        }, {} as SelectedServicesType);
        setSelectedServicesType(services);
      }
    };

    loadData();
  }, [contractorCategoryTypes, handleChangeType, vehicleItem]);

  useEffect(() => {
    if (!vehicleItem?.id) {
      return;
    }

    const manufacturerFounded = manufacturerTypes.find(
      item => item.name.toUpperCase() === vehicleItem.manufacture.toUpperCase(),
    );
    const vehicleFounded = vehiclesTypes.find(item => item.name.toUpperCase() === vehicleItem.model.toUpperCase());
    const modelFounded = modelsTypes.find(item => item.name.toUpperCase() === vehicleItem.model_year.toUpperCase());

    setManufactorSelected(manufacturerFounded);
    setVehicleSelected(vehicleFounded);
    setModelSelected(modelFounded);
  }, [manufacturerTypes, modelsTypes, vehicleItem, vehiclesTypes]);

  return (
    <DetailsDialog
      open={!!vehicleItem || !!newVehicle}
      editable
      editing
      onRequestSave={handleSubmit}
      loading={
        (!vehicleItem && !newVehicle) ||
        (vehicleItem &&
          (!manufactorSelected ||
            !vehicleSelected ||
            !modelSelected ||
            !selectedServicesType ||
            !selectedCategoryTypeId))
      }
      onCancelEditing={handleCloseModal}
      {...props}
      onClose={handleCloseModal}
    >
      <DataTree>
        <DataGroup title='Tipo de veículo'>
          <FormControl disabled={!!vehicleItem}>
            <Select
              labelId='categoryType'
              id='categoryType-select'
              value={selectedCategoryTypeId}
              onChange={({ target: { value } }) => handleChangeType(value as string)}
            >
              {contractorCategoryTypes?.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DataGroup>

        {selectedCategoryType?.subtypes && (
          <DataGroup title='Subtipo de veículo'>
            <FormControl disabled={!!vehicleItem}>
              <Select
                labelId='subcategoryType'
                id='subcategoryType-select'
                value={selectedSubCategoryTypeId}
                onChange={({ target: { value } }) => handleChangeSubType(value as string)}
              >
                {selectedCategoryType.subtypes.map(type => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DataGroup>
        )}

        {servicesType && servicesType.length > 0 && (
          <>
            <DataGroup title='Serviços prestado'>
              <FormGroup>
                {servicesType.map(type => (
                  <FormControlLabel
                    key={type.id}
                    control={
                      <Checkbox
                        color='primary'
                        checked={selectedServicesType && selectedServicesType[type.id]}
                        onChange={handleChangeService}
                        value={type.id}
                      />
                    }
                    label={type.description}
                  />
                ))}
              </FormGroup>
            </DataGroup>

            <DataGroup title='Veículo'>
              <DataItem title='Marca'>
                <CustomAutocomplete
                  id='combo-box-associations'
                  options={manufacturerTypes}
                  getOptionLabel={item => item.name}
                  renderInput={params => <TextField {...params} label='Selecione uma marca' />}
                  onChange={(_, value) => {
                    setManufactorSelected(value);
                  }}
                  value={manufactorSelected}
                  defaultValue={manufactorSelected}
                  loading={loadingManufacturer}
                  loadingText='Carregando...'
                />
              </DataItem>

              <DataItem title='Modelo'>
                <CustomAutocomplete
                  id='combo-box-associations'
                  options={vehiclesTypes}
                  getOptionLabel={item => item.name}
                  renderInput={params => <TextField {...params} label='Selecione um modelo' />}
                  onChange={(_, value) => {
                    setVehicleSelected(value);
                  }}
                  value={vehicleSelected}
                  loading={loadingVehicles}
                  loadingText='Carregando...'
                />
              </DataItem>

              <DataItem title='Ano'>
                <CustomAutocomplete
                  id='combo-box-associations'
                  options={modelsTypes}
                  getOptionLabel={item => item.name}
                  renderInput={params => <TextField {...params} label='Selecione um ano' />}
                  onChange={(_, value) => {
                    setModelSelected(value);
                  }}
                  value={modelSelected}
                  loading={loadingModels}
                  loadingText='Carregando...'
                />
              </DataItem>

              <TextDataItem
                title='Placa'
                value={newData?.license_plate}
                editing
                onChange={handleChangeField('license_plate')}
                otherProps={{
                  inputProps: {
                    maxLength: 7,
                  },
                }}
              />

              <TextDataItem title='Chassi' value={newData?.vin} editing onChange={handleChangeField('vin')} />

              <TextDataItem title='Cor' value={newData?.color} editing onChange={handleChangeField('color')} />

              <TextDataItem title='Cidade' value={newData?.city} editing onChange={handleChangeField('city')} />

              <TextDataItem title='Estado' value={newData?.state} editing onChange={handleChangeField('state')} />
            </DataGroup>
          </>
        )}
      </DataTree>
    </DetailsDialog>
  );
}
